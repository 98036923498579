.App {
  /*display: flex*/
}


*:focus {
    outline: none;
}

/*.ReactTable{
	 background-color: #fff;
	 box-shadow: 4px 4px 8px 0px rgba( 0, 0, 0, 0.2 );
	 border-radius: .5rem;
}*/
.pointer{
	cursor: pointer;
}
#password-field{
	height: auto !important;
}
.ReactTable{
	border-radius: 0 0 4px 4px;
	border-bottom: none !important;
	border-right: none !important;
	border-left: none !important;
	border-top: 1px solid rgba(0,0,0,0.1) !important;
	background-color: #fff;
}
.ReactTable .rt-thead .rt-th.-cursor-pointer {
	padding: 16px 0 16px 0;
}
.ReactTable .rt-tbody .rt-tr-group:last-child {
    border-bottom: solid 1px rgba(0,0,0,0.05) !important;
}
.ReactTable .rt-tbody .rt-expandable {
    cursor: pointer;
    text-overflow: clip;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Toastify__toast {
	box-shadow: none;
	border: 1px solid #D9DFEB;
    border-radius: 10px;
    background-color: #fff;
}

.Toastify__close-button {
	align-self: center;
}

/*.ReactTable{
	 background-color: #fff;
	box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12);
	 box-shadow: none;
	 border-radius: .5rem;
	 border-radius: 0px;
	 border: none;
}*/

/*.ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td {
	border: none;
}
.ReactTable .rt-tbody .rt-td {
	border-right: none;
}
.ReactTable .rt-thead.-header {
    border-top: solid 1px rgba(0,0,0,0.05);
}*/



